import React from 'react';
import { Grid, Avatar, TextField, MenuItem } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { selectUser, selectUserActivePosition, storeUserActivePosition } from '../../reducers/userSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';

interface ISwitchActivePosition {
  dark_mode: boolean;
}

const SwitchActivePosition: React.FC<ISwitchActivePosition> = (props: ISwitchActivePosition) => {
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(selectUser);
  const loggedUserActivePosition = useAppSelector(selectUserActivePosition);
  const [position, setPosition] = React.useState(loggedUserActivePosition?.id || loggedUser?.positions ? loggedUser?.positions[0]?.id : UNDEFINED_AS_ANY);

  const ALL_POSITIONS = loggedUser?.positions?.filter((p: any) => p?.status === 'FILLED');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: any = event.target.value;
    setPosition(value);
    const newPosition = ALL_POSITIONS?.find((p: any) => p?.id === value);
    dispatch(storeUserActivePosition(newPosition || ALL_POSITIONS[0]));
  };

  React.useEffect(() => {
    if (!loggedUserActivePosition?.id && loggedUser?.positions && ALL_POSITIONS?.length > 0) {
      dispatch(storeUserActivePosition(ALL_POSITIONS[0]));
    }

    if (position?.id !== loggedUserActivePosition?.id) {
      dispatch(storeUserActivePosition(ALL_POSITIONS?.find((p: any) => p?.id === loggedUserActivePosition?.id)));
      setPosition(loggedUserActivePosition?.id);
    }
  }, [loggedUser, loggedUserActivePosition, dispatch, position, ALL_POSITIONS]);

  return (
    <>
      <TextField
        value={position}
        select
        // label={`As ${loggedUserActivePosition?.role}`}
        onChange={handleChange}
        sx={{
          'display': loggedUser?.positions?.length > 0 && loggedUser?.positions !== undefined ? 'block' : 'none',
          'color': 'red !important',
          '& .options': {
            justifyContent: 'center',
          },
        }}
        SelectProps={{
          MenuProps: {
            sx: {
              zIndex: '1000000000',
            },
          },
          sx: {
            '& fieldset': {
              border: 'none',
            },
            '& .MuiSelect-select': {
              'pb': '5px',
              'pt': '5px',
              'mt': '10px',
              'color': props.dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15, 15, 15, 1)',
              'bgcolor': props.dark_mode ? 'rgba(247, 251, 250, .04)' : '',
              'borderRadius': '10px',
              '&:hover': {
                bgcolor: props.dark_mode ? 'rgba(247, 251, 250, .1)' : 'rgba(15, 15, 15, .05)',
              },
            },
            '& .MuiSelect-icon': {
              color: props.dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15, 15, 15, 1)',
            },
          },
        }}
        InputLabelProps={{
          sx: {
            'width': '100%',
            'textAlign': 'center',
            'overflow': 'visible',
            'color': props.dark_mode ? 'rgba(247, 251, 250, 0.3)' : 'rgba(15, 15, 15, 1)',
            '&.Mui-focused': {
              color: props.dark_mode ? 'rgba(247, 251, 250, 0.3)' : 'rgba(15, 15, 15, 1)',
            },
          },
        }}
      >
        {ALL_POSITIONS?.map((p: any) => (
          <MenuItem key={p?.id} value={p?.id}>
            <Grid container className="options" sx={{ alignItems: 'center', fontSize: '.8em', justifyContent: 'start' }}>
              <Avatar variant="rounded" src={p?.company?.logo?.path} alt={p?.company?.label} sx={{ width: '25px', height: '25px' }} />
              &nbsp;
              <strong>
                {p?.company?.label} - {p?.role}
              </strong>
            </Grid>
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

SwitchActivePosition.propTypes = {};

SwitchActivePosition.defaultProps = {
  dark_mode: false,
};

export default React.memo(SwitchActivePosition);
