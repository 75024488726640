import React from 'react';
import { Link, BrowserRouter, Routes, Route } from 'react-router-dom';
import _ from 'lodash';
import { useQuery, gql } from '@apollo/client';
import { Slide, Badge, Button, List, ListItemButton, SwipeableDrawer, CssBaseline, Grid, BottomNavigation, BottomNavigationAction, Paper, Avatar, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { AddBoxOutlined, PostAdd } from '@mui/icons-material';
import { PurchasesIcon, ChatsIcon, HomeIcon, SingleMemberIcon, DashboardIcon, ShoppingCartIcon } from './img/icons/Icons';
import { useAppSelector } from './redux/hooks';
import { selectSession } from './reducers/sessionSlice';
import { selectUser, selectUserActivePosition } from './reducers/userSlice';
import { selectCompanies } from './reducers/companySlice';
import { selectCarts } from './reducers/cartSlice';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { translations } from './lang/translations';
import { UAParser, IResult } from 'ua-parser-js';
import ipLocation from 'iplocation';
import moment from 'moment';

import { REACT_APP_IP_INFO_TOKEN } from './config';
import { UNDEFINED_AS_ANY } from './utilities/CommonInterfaces';

import SwitchActivePosition from './component-modals/common/SwitchActivePosition';
import TriggerPWA from './component-modals/common/TriggerPWA';
import ScrollToTop from './component-modals/common/ScrollTop';
import SessionController from './component-modals/common/SessionController';
import useEventLog from './hooks/useEventLog';
import LazyLoadingWrapper from './LazyLoadingWrapper';

const Home = React.lazy(() => import('./components/Home'));
const LoginPage = React.lazy(() => import('./components/profile/LoginPage'));
const FormSignIn2F = React.lazy(() => import('./components/profile/FormSignIn2F'));
const SignUpPage = React.lazy(() => import('./components/profile/SignUpPage'));
const OnBoarding = React.lazy(() => import('./components/profile/OnBoarding'));
const Profile = React.lazy(() => import('./components/profile/Profile'));
const ProfileEditForm = React.lazy(() => import('./components/profile/ProfileEditForm'));
const LegalMentions = React.lazy(() => import('./components/preferences/LegalMentions'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const FormNewFlex = React.lazy(() => import('./components/flex_news/FormNewFlex'));
const Flex = React.lazy(() => import('./components/flex_news/Flex'));
const FormEditFlex = React.lazy(() => import('./components/flex_news/FormEditFlex'));
const Opportunity = React.lazy(() => import('./components/opportunities/Opportunity'));
const FormEditOpportunity = React.lazy(() => import('./components/opportunities/FormEditOpportunity'));
const Settings = React.lazy(() => import('./components/preferences/Settings'));
const Languages = React.lazy(() => import('./components/preferences/Languages'));
const ContactUs = React.lazy(() => import('./components/preferences/ContactUs'));
const BlockedList = React.lazy(() => import('./components/preferences/BlockedList'));
const Wallets = React.lazy(() => import('./components/wallet/Wallets'));
const Account = React.lazy(() => import('./components/preferences/Account'));
const FormNewWallet = React.lazy(() => import('./components/wallet/FormNewWallet'));
const KYCNotice = React.lazy(() => import('./components/wallet/KYCNotice'));
const Wallet = React.lazy(() => import('./components/wallet/Wallet'));
const FormNewUserAssociation = React.lazy(() => import('./components/wallet/FormNewUserAssociation'));
const FormNewRequestBankAccount = React.lazy(() => import('./components/wallet/FormNewRequestBankAccount'));
const RequestBankAccount = React.lazy(() => import('./components/partnerships/center_fintechs/RequestBankAccount'));
const FormNewBankAccount = React.lazy(() => import('./components/wallet/FormNewBankAccount'));
const NewBankAccountNotice = React.lazy(() => import('./components/wallet/NewBankAccountNotice'));
const BankAccount = React.lazy(() => import('./components/wallet/BankAccount'));
const FormOrderBankCard = React.lazy(() => import('./components/wallet/FormOrderBankCard'));
const FormAddBankCard = React.lazy(() => import('./components/wallet/FormAddBankCard'));
const BankCard = React.lazy(() => import('./components/wallet/BankCard'));
const FormAddMobileMoneyAccount = React.lazy(() => import('./components/wallet/FormAddMobileMoneyAccount'));
const MobileMoneyAccount = React.lazy(() => import('./components/wallet/MobileMoneyAccount'));
const FormNewBlockchainAccount = React.lazy(() => import('./components/wallet/FormNewBlockchainAccount'));
const BlockchainAccount = React.lazy(() => import('./components/wallet/BlockchainAccount'));
const FormAddPaymentMethod = React.lazy(() => import('./components/wallet/FormAddPaymentMethod'));
const PaymentMethod = React.lazy(() => import('./components/wallet/PaymentMethod'));
const AlertPage = React.lazy(() => import('./components/partnerships/center_risks_management/Alert'));
const FormEditAlert = React.lazy(() => import('./components/partnerships/center_risks_management/FormEditAlert'));
const FormNewWebinar = React.lazy(() => import('./components/partnerships/center_risks_management/FormNewWebinar'));
const Webinar = React.lazy(() => import('./components/partnerships/center_risks_management/Webinar'));
const FormEditWebinar = React.lazy(() => import('./components/partnerships/center_risks_management/FormEditWebinar'));
const Search = React.lazy(() => import('./components/items/Search'));
const Product = React.lazy(() => import('./components/items/Product'));
const FormEditProduct = React.lazy(() => import('./components/items/FormEditProduct'));
const Service = React.lazy(() => import('./components/items/Service'));
const FormEditService = React.lazy(() => import('./components/items/FormEditService'));
const Tender = React.lazy(() => import('./components/items/Tender'));
const FormEditTender = React.lazy(() => import('./components/items/FormEditTender'));
const Rental = React.lazy(() => import('./components/items/Rental'));
const FormEditRental = React.lazy(() => import('./components/items/FormEditRental'));
const Cart = React.lazy(() => import('./components/cart/Cart'));
const CartCheckout = React.lazy(() => import('./components/cart/CartCheckout'));
const CartSummary = React.lazy(() => import('./components/cart/CartSummary'));
const RequestLogistics = React.lazy(() => import('./components/partnerships/center_logistics/RequestLogistics'));
const FormNewQuotation = React.lazy(() => import('./components/partnerships/center_logistics/FormNewQuotation'));
const FormEditPackageItinerary = React.lazy(() => import('./components/partnerships/center_logistics/FormEditPackageItinerary'));
const RequestFinancing = React.lazy(() => import('./components/partnerships/center_fintechs/RequestFinancing'));
const RequestAudit = React.lazy(() => import('./components/partnerships/center_audits/RequestAudit'));
const FormNewStock = React.lazy(() => import('./components/stocks/FormNewStock'));
const Stock = React.lazy(() => import('./components/stocks/Stock'));
const FormEditStock = React.lazy(() => import('./components/stocks/FormEditStock'));
const FormNewWarehouse = React.lazy(() => import('./components/stocks/FormNewWarehouse'));
const Warehouse = React.lazy(() => import('./components/stocks/Warehouse'));
const FormEditWarehouse = React.lazy(() => import('./components/stocks/FormEditWarehouse'));
const FormNewSupplychain = React.lazy(() => import('./components/supplychains/FormNewSupplychain'));
const Supplychain = React.lazy(() => import('./components/supplychains/Supplychain'));
const FormEditSupplychain = React.lazy(() => import('./components/supplychains/FormEditSupplychain'));
const Supplychains = React.lazy(() => import('./components/supplychains/Supplychains'));
const ListConversations = React.lazy(() => import('./components/contacts/ListConversations'));
const Conversation = React.lazy(() => import('./components/contacts/Conversation'));
const EditConversation = React.lazy(() => import('./components/contacts/EditConversation'));
const ListContacts = React.lazy(() => import('./components/contacts/ListContacts'));
const ManageCompanyMembers = React.lazy(() => import('./components/company/ManageCompanyMembers'));
const FormNewPosition = React.lazy(() => import('./components/company/FormNewPosition'));
const Position = React.lazy(() => import('./components/company/Position'));
const ListPurchases = React.lazy(() => import('./components/ListPurchases'));
const ListSells = React.lazy(() => import('./components/deals/ListSells'));
const FormNewDeal = React.lazy(() => import('./components/deals/FormNewDeal'));
const FormNewMultiDeals = React.lazy(() => import('./components/deals/FormNewMultiDeals'));
const Deal = React.lazy(() => import('./components/deals/Deal'));
const FormEditDeal = React.lazy(() => import('./components/deals/FormEditDeal'));
const Order = React.lazy(() => import('./components/orders/Order'));
const FormEditOrder = React.lazy(() => import('./components/orders/FormEditOrder'));
const EmailVerification = React.lazy(() => import('./components/profile/EmailVerification'));
const FormNewComplexOwner = React.lazy(() => import('./components/partnerships/center_five_owners/FormNewComplexOwner'));
const JoinComplexOwnerNetwork = React.lazy(() => import('./components/partnerships/center_five_owners/JoinComplexOwnerNetwork'));
const OnBoardingComplexOwner = React.lazy(() => import('./components/partnerships/center_five_owners/OnBoardingComplexOwner'));
const FormEditComplexOwner = React.lazy(() => import('./components/partnerships/center_five_owners/FormEditComplexOwner'));
const FormNewPlayer = React.lazy(() => import('./components/partnerships/center_five_owners/FormNewPlayer'));
const FormEditPlayer = React.lazy(() => import('./components/partnerships/center_five_owners/FormEditPlayer'));
const FormNewComplex = React.lazy(() => import('./components/partnerships/center_five_owners/FormNewComplex'));
const Complex = React.lazy(() => import('./components/partnerships/center_five_owners/Complex'));
const FormEditComplex = React.lazy(() => import('./components/partnerships/center_five_owners/FormEditComplex'));
const FormNewField = React.lazy(() => import('./components/partnerships/center_five_owners/FormNewField'));
const Field = React.lazy(() => import('./components/partnerships/center_five_owners/Field'));
const FormEditField = React.lazy(() => import('./components/partnerships/center_five_owners/FormEditField'));
const HomeAdmin = React.lazy(() => import('./components/admin/HomeAdmin'));
const FormNewCompany = React.lazy(() => import('./components/company/FormNewCompany'));
const FormEditCompany = React.lazy(() => import('./components/company/FormEditCompany'));
const CompanyMembers = React.lazy(() => import('./components/company/CompanyMembers'));
const Company = React.lazy(() => import('./components/company/Company'));
const Store = React.lazy(() => import('./components/company/Store'));
const NoMatch = React.lazy(() => import('./components/NoMatch'));
const NewPostModal = React.lazy(() => import('./component-modals/common/NewPostModal'));
const JobMission = React.lazy(() => import('./components/opportunities/JobMission'));
const FormEditJobMission = React.lazy(() => import('./components/opportunities/FormEditJobMission'));
const CompanyBadge = React.lazy(() => import('./components/company/CompanyBadge'));
const CompanyRecommendationsAdmin = React.lazy(() => import('./components/admin/CompanyRecommendationsAdmin'));
const UsersAdmin = React.lazy(() => import('./components/admin/UsersAdmin'));
const PartnershipsAdmin = React.lazy(() => import('./components/admin/PartnershipsAdmin'));
const KYCsAdmin = React.lazy(() => import('./components/admin/KYCsAdmin'));
const Livestream = React.lazy(() => import('./components/contacts/Livestream'));
const EmailVerificationAlert = React.lazy(() => import('./component-modals/common/EmailVerificationAlert'));
const LivestreamManager = React.lazy(() => import('./components/contacts/LivestreamManager'));
const FormNewLivestream = React.lazy(() => import('./components/contacts/FormNewLivestream'));

interface IAppContent {
  currentPosition: any;
  currentDevice: any;
  registration: any;
}

const App: React.FC = () => {
  const FIND_CONVERSATIONS_BY_PARTICIPANTS_IDS_QUERY = gql`
    query findConversationByParticipantsIDs($conversations: inputFindConversationByParticipantsIDs!) {
      findConversationByParticipantsIDs(conversations: $conversations) {
        id
        short_id
        title
        messages {
          id
          status
          viewers {
            ... on Company {
              __typename
              id
            }
            ... on User {
              __typename
              id
            }
            ... on Player {
              __typename
              id
            }
            ... on ComplexOwner {
              __typename
              id
            }
            ... on Transporter {
              __typename
              id
            }
            ... on Passenger {
              __typename
              id
            }
            ... on Patient {
              __typename
              id
            }
            ... on HealthWorker {
              __typename
              id
            }
          }
        }
      }
    }
  `;

  const loggedUser = useAppSelector(selectUser);
  const loggedUserActivePosition = useAppSelector(selectUserActivePosition);
  const loggedUserCompanies = useAppSelector(selectCompanies);
  const loggedUserCarts = useAppSelector(selectCarts);
  const session = useAppSelector(selectSession);
  const { handleSendLog } = useEventLog();

  const loadedConversations = useQuery(FIND_CONVERSATIONS_BY_PARTICIPANTS_IDS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip:
      !session?.token?.key ||
      _.concat(
        loggedUser?.id,
        loggedUserCompanies?.map((c: any) => c?.id)
      )?.filter((c: any) => c)?.length === 0,
    variables: {
      conversations: {
        participants: _.concat(
          loggedUser?.id,
          loggedUserCompanies?.map((c: any) => c?.id)
        )?.filter((c: any) => c),
        offset: 0,
      },
    },
    pollInterval: !loggedUser?.id ? undefined : 300000,
  });

  const [device, setDevice] = React.useState<IResult>();
  const [currentPosition, setCurrentPosition] = React.useState<any>();
  const [value, setValue] = React.useState('home');
  const [openPost, setOpenPost] = React.useState(false);
  const [state, setState] = React.useState({
    bottom: false,
  });
  const [registration, setRegistration] = React.useState(UNDEFINED_AS_ANY);
  const trigger = useScrollTrigger();
  const isSmallMobile = useMediaQuery('(max-width:600px)');
  const isMobile = useMediaQuery('(min-width:601px) and (max-width:767px)');
  const isTablet = useMediaQuery('(min-width:768px) and (max-width:1023px)');

  const ALL_ITEMS = _.sum(loggedUserCarts?.map((cart: any) => cart?.list?.length));
  const ALL_PARTICIPANTS = _.concat(loggedUser, loggedUserCompanies);

  const handleChange = (event: React.ChangeEvent<any>, newValue: string) => {
    setValue(newValue);
  };

  type Anchor = 'bottom';
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    const action: any = event;
    if (action && action.type === 'keydown' && (action.key === 'Tab' || action.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const ANCHORS: Anchor[] = ['bottom'];

  async function setLocale(locale: string) {
    // Dynamically import the moment locale file
    await import(`moment/locale/${locale}`);
    // Set the locale
    moment.locale(locale);
  }

  const handleOpenNewPost = (anchor: any) => {
    setOpenPost(true);
    toggleDrawer(anchor, false);
  };

  React.useEffect(() => {
    (async () => {
      try {
        await setLocale(loggedUser?.app_configuration?.prefered_language?.toLowerCase());

        const my_ip = await fetch(`https://ipinfo.io?token=${REACT_APP_IP_INFO_TOKEN}`);
        const ip_data = await my_ip.json();
        const location = await ipLocation(ip_data.ip);

        // navigator.geolocation.watchPosition((p: any) => console.log(p))
        // navigator.geolocation.getCurrentPosition((p: any) => console.log(p))
        // console.log('session', session)
        if (!currentPosition) {
          navigator.geolocation.getCurrentPosition(
            (position: any) => {
              setCurrentPosition(location || position.coords);
            },
            () => {
              setCurrentPosition(location);
            }
          );
        }

        if (!device) {
          const parser = new UAParser();
          setDevice(parser.getResult());
        }
      } catch (e: any) {
        // console.error(e);
        handleSendLog(e.toString(), session?.app_client?.id);
        if (!currentPosition) {
          navigator.geolocation.getCurrentPosition(
            (position: any) => {
              setCurrentPosition(position.coords);
            },
            () => {
              setCurrentPosition(location);
            }
          );
        }
      }

      try {
        const r = await navigator.serviceWorker.getRegistration();
        setRegistration(r);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, [UAParser, ipLocation, navigator, currentPosition, device]);

  React.useEffect(() => {
    if (session?.status && value !== window.location.pathname) {
      if (window.location.pathname === '/' && value !== 'home') {
        setValue('home');
      }

      if (window.location.pathname !== '/') {
        setValue(window.location.pathname?.replaceAll('/', ''));
      }
    }
  }, [value, session, window.location.pathname]);

  return (
    <BrowserRouter>
      <IntlProvider
        messages={translations?.find((t: any) => t?.value === (translations?.find((t: any) => t?.value === loggedUser?.app_configuration?.prefered_language) ? loggedUser?.app_configuration?.prefered_language : _.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[1] === 'FR' ? 'FR' : 'GB'))?.content}
        locale={_.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[1]}
        defaultLocale={_.split(window.navigator.languages?.filter((l: any) => l?.includes('-'))[0], '-')[0] ?? 'en'}
      >
        <Grid container>
          <AppContext.Provider value={{ currentPosition: currentPosition, currentDevice: device, registration: registration }}>
            <Grid container sx={{ minHeight: '100vh', justifyContent: 'center' }}>
              <ScrollToTop />
              <CssBaseline />
              <SessionController />
              <TriggerPWA />

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  textAlign: 'center',
                  position: 'fixed',
                  width: '80%',
                  zIndex: '200000000000',
                  mt: '10px',
                  display: window.location.pathname === '/onboarding' ? 'none' : 'block',
                }}
              >
                <LazyLoadingWrapper>
                  <EmailVerificationAlert />
                </LazyLoadingWrapper>
              </Grid>

              <Grid className={'main-container'} sx={{ minHeight: '100vh', pb: { xs: '150px', md: '0' } }}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <LazyLoadingWrapper>
                        <Home />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/login"
                    element={
                      <LazyLoadingWrapper>
                        <LoginPage />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/two_facts"
                    element={
                      <LazyLoadingWrapper>
                        <FormSignIn2F />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <LazyLoadingWrapper>
                        <SignUpPage />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/onboarding"
                    element={
                      <LazyLoadingWrapper>
                        <OnBoarding />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/profile"
                    element={
                      <LazyLoadingWrapper>
                        <Profile />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/profile/edit"
                    element={
                      <LazyLoadingWrapper>
                        <ProfileEditForm />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/u/:userId"
                    element={
                      <LazyLoadingWrapper>
                        <Profile />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/u/:userId/edit"
                    element={
                      <LazyLoadingWrapper>
                        <ProfileEditForm />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/legal_mentions"
                    element={
                      <LazyLoadingWrapper>
                        <LegalMentions />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <LazyLoadingWrapper>
                        <Dashboard />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/new_flex"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewFlex />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/f/:flexId"
                    element={
                      <LazyLoadingWrapper>
                        <Flex />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/f/flex/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditFlex />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/op/:opportunityId"
                    element={
                      <LazyLoadingWrapper>
                        <Opportunity />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/op/opportunity/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditOpportunity />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/jo/:jobMissionId"
                    element={
                      <LazyLoadingWrapper>
                        <JobMission />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/jo/job_mission/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditJobMission />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/settings"
                    element={
                      <LazyLoadingWrapper>
                        <Settings />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/settings/languages"
                    element={
                      <LazyLoadingWrapper>
                        <Languages />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/settings/contact_us"
                    element={
                      <LazyLoadingWrapper>
                        <ContactUs />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/settings/blocked_accounts"
                    element={
                      <LazyLoadingWrapper>
                        <BlockedList />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/settings/wallets"
                    element={
                      <LazyLoadingWrapper>
                        <Wallets />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/settings/account"
                    element={
                      <LazyLoadingWrapper>
                        <Account />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/new_wallet"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewWallet />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/kyc_notice"
                    element={
                      <LazyLoadingWrapper>
                        <KYCNotice />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/w/wallet"
                    element={
                      <LazyLoadingWrapper>
                        <Wallet />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/w/wallets"
                    element={
                      <LazyLoadingWrapper>
                        <Wallets />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/w/add_secondary_user"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewUserAssociation />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/bk/new_request_bank_account"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewRequestBankAccount />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/bk/request_bank_account"
                    element={
                      <LazyLoadingWrapper>
                        <RequestBankAccount />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/bk/new_bank_account"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewBankAccount />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/bk/new_bank_account_notice"
                    element={
                      <LazyLoadingWrapper>
                        <NewBankAccountNotice />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/bk/account"
                    element={
                      <LazyLoadingWrapper>
                        <BankAccount />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/bk/order_bank_card"
                    element={
                      <LazyLoadingWrapper>
                        <FormOrderBankCard />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/bk/new_bank_card"
                    element={
                      <LazyLoadingWrapper>
                        <FormAddBankCard />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/bk/card"
                    element={
                      <LazyLoadingWrapper>
                        <BankCard />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/mm/new_mobile_money_account"
                    element={
                      <LazyLoadingWrapper>
                        <FormAddMobileMoneyAccount />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/mm/account"
                    element={
                      <LazyLoadingWrapper>
                        <MobileMoneyAccount />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/bc/new_blockchain_account"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewBlockchainAccount />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/bc/account"
                    element={
                      <LazyLoadingWrapper>
                        <BlockchainAccount />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/pm/add_payment_method"
                    element={
                      <LazyLoadingWrapper>
                        <FormAddPaymentMethod />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/pm/payment_method"
                    element={
                      <LazyLoadingWrapper>
                        <PaymentMethod />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/al/:alertId"
                    element={
                      <LazyLoadingWrapper>
                        <AlertPage />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/al/:alertId/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditAlert />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/new_webinar"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewWebinar />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/wn/:webinarId"
                    element={
                      <LazyLoadingWrapper>
                        <Webinar />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/wn/:webinarId/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditWebinar />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/search"
                    element={
                      <LazyLoadingWrapper>
                        <Search />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/p/:productId"
                    element={
                      <LazyLoadingWrapper>
                        <Product />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/p/product/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditProduct />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/s/:serviceId"
                    element={
                      <LazyLoadingWrapper>
                        <Service />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/s/service/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditService />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/t/:tenderId"
                    element={
                      <LazyLoadingWrapper>
                        <Tender />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/t/tender/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditTender />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/r/:rentalId"
                    element={
                      <LazyLoadingWrapper>
                        <Rental />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/r/rental/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditRental />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/cart"
                    element={
                      <LazyLoadingWrapper>
                        <Cart />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/cart_checkout"
                    element={
                      <LazyLoadingWrapper>
                        <CartCheckout />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/cart_summary"
                    element={
                      <LazyLoadingWrapper>
                        <CartSummary />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/rl/:requestId"
                    element={
                      <LazyLoadingWrapper>
                        <RequestLogistics />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/new_quotation"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewQuotation />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/it/packageItinerary/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditPackageItinerary />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/rf/:requestId"
                    element={
                      <LazyLoadingWrapper>
                        <RequestFinancing />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/ra/:requestId"
                    element={
                      <LazyLoadingWrapper>
                        <RequestAudit />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/new_stock"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewStock />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/st/:stockId"
                    element={
                      <LazyLoadingWrapper>
                        <Stock />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/st/stock/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditStock />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/new_warehouse"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewWarehouse />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/wh/:warehouseId"
                    element={
                      <LazyLoadingWrapper>
                        <Warehouse />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/wh/warehouse/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditWarehouse />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/new_supplychain"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewSupplychain />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/sc/:supplychainId"
                    element={
                      <LazyLoadingWrapper>
                        <Supplychain />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/sc/supplychain/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditSupplychain />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/supplychains"
                    element={
                      <LazyLoadingWrapper>
                        <Supplychains />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/conversations"
                    element={
                      <LazyLoadingWrapper>
                        <ListConversations />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/c/:conversationId"
                    element={
                      <LazyLoadingWrapper>
                        <Conversation />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/c/conversation/edit"
                    element={
                      <LazyLoadingWrapper>
                        <EditConversation />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/list_contacts"
                    element={
                      <LazyLoadingWrapper>
                        <ListContacts />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/company/manage_members"
                    element={
                      <LazyLoadingWrapper>
                        <ManageCompanyMembers />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/new_position"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewPosition />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/j/:positionId"
                    element={
                      <LazyLoadingWrapper>
                        <Position />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/purchases"
                    element={
                      <LazyLoadingWrapper>
                        <ListPurchases />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/sells"
                    element={
                      <LazyLoadingWrapper>
                        <ListSells />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/new_deal"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewDeal />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/new_multi_buyers_deal"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewMultiDeals />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/d/:dealId"
                    element={
                      <LazyLoadingWrapper>
                        <Deal />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/d/deal/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditDeal />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/o/:orderId"
                    element={
                      <LazyLoadingWrapper>
                        <Order />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/o/order/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditOrder />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/email_verification/:key"
                    element={
                      <LazyLoadingWrapper>
                        <EmailVerification />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route path="/join_complex_owner_network" element={<LazyLoadingWrapper>{loggedUser?.id ? <FormNewComplexOwner /> : <JoinComplexOwnerNetwork />}</LazyLoadingWrapper>} />
                  <Route
                    path="/join_fivezer_network"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewComplexOwner />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/onboarding_complex_owner"
                    element={
                      <LazyLoadingWrapper>
                        <OnBoardingComplexOwner />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/complex_owner/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditComplexOwner />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/new_player"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewPlayer />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/player/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditPlayer />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/new_complex"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewComplex />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/cx/:complexId"
                    element={
                      <LazyLoadingWrapper>
                        <Complex />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/cx/complex/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditComplex />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/new_field"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewField />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/fd/:fieldId"
                    element={
                      <LazyLoadingWrapper>
                        <Field />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/fd/field/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditField />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/new_livestream"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewLivestream />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/manage_livestream"
                    element={
                      <LazyLoadingWrapper>
                        <LivestreamManager />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/ls/:livestreamId"
                    element={
                      <LazyLoadingWrapper>
                        <Livestream />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="/africantum"
                    element={
                      <LazyLoadingWrapper>
                        <HomeAdmin />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/africantum/users"
                    element={
                      <LazyLoadingWrapper>
                        <UsersAdmin />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/africantum/company_partnerships"
                    element={
                      <LazyLoadingWrapper>
                        <PartnershipsAdmin />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/africantum/kycs"
                    element={
                      <LazyLoadingWrapper>
                        <KYCsAdmin />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/africantum/company_recommendations"
                    element={
                      <LazyLoadingWrapper>
                        <CompanyRecommendationsAdmin />
                      </LazyLoadingWrapper>
                    }
                  />

                  {/* ANYTHING BELOW MUST STAY AT LAST POSITION */}
                  <Route
                    path="/new_company"
                    element={
                      <LazyLoadingWrapper>
                        <FormNewCompany />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/company/edit"
                    element={
                      <LazyLoadingWrapper>
                        <FormEditCompany />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/:companyName/members"
                    element={
                      <LazyLoadingWrapper>
                        <CompanyMembers />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/:companyName"
                    element={
                      <LazyLoadingWrapper>
                        <Company />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/badge/:companyName"
                    element={
                      <LazyLoadingWrapper>
                        <CompanyBadge />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/:companyName/store"
                    element={
                      <LazyLoadingWrapper>
                        <Store />
                      </LazyLoadingWrapper>
                    }
                  />
                  <Route
                    path="/:companyName/supplychains"
                    element={
                      <LazyLoadingWrapper>
                        <Supplychains />
                      </LazyLoadingWrapper>
                    }
                  />

                  <Route
                    path="*"
                    element={
                      <LazyLoadingWrapper>
                        <NoMatch />
                      </LazyLoadingWrapper>
                    }
                  />

                  {/* <Route path="/login" element={<LoginPage />} />
                  <Route path="/two_facts" element={<FormSignIn2F />} />
                  <Route path="/signup" element={<SignUpPage />} />
                  <Route path="/onboarding" element={<OnBoarding />} />

                  <Route path="/profile" element={<Profile />} />
                  <Route path="/profile/edit" element={<ProfileEditForm />} />
                  <Route path="/u/:userId" element={<Profile />} />
                  <Route path="/u/:userId/edit" element={<ProfileEditForm />} />
                  <Route path="/legal_mentions" element={<LegalMentions />} />
                  <Route path="/dashboard" element={<Dashboard />} />

                  <Route path="/new_flex" element={<FormNewFlex />} />
                  <Route path="/f/:flexId" element={<Flex />} />
                  <Route path="/f/flex/edit" element={<FormEditFlex />} />

                  <Route path="/op/:opportunityId" element={<Opportunity />} />
                  <Route path="/op/opportunity/edit" element={<FormEditOpportunity />} />
                  <Route path="/jo/:jobMissionId" element={<JobMission />} />
                  <Route path="/jo/job_mission/edit" element={<FormEditJobMission />} />

                  <Route path="/settings" element={<Settings />} />
                  <Route path="/settings/languages" element={<Languages />} />
                  <Route path="/settings/contact_us" element={<ContactUs />} />
                  <Route path="/settings/blocked_accounts" element={<BlockedList />} />
                  <Route path="/settings/wallets" element={<Wallets />} />
                  <Route path="/settings/account" element={<Account />} />

                  <Route path="/new_wallet" element={<FormNewWallet />} />
                  <Route path="/kyc_notice" element={<KYCNotice />} />
                  <Route path="/w/wallet" element={<Wallet />} />
                  <Route path="/w/wallets" element={<Wallets />} />
                  <Route path="/w/add_secondary_user" element={<FormNewUserAssociation />} />

                  <Route path="/bk/new_request_bank_account" element={<FormNewRequestBankAccount />} />
                  <Route path="/bk/request_bank_account" element={<RequestBankAccount />} />
                  <Route path="/bk/new_bank_account" element={<FormNewBankAccount />} />
                  <Route path="/bk/new_bank_account_notice" element={<NewBankAccountNotice />} />
                  <Route path="/bk/account" element={<BankAccount />} />
                  <Route path="/bk/order_bank_card" element={<FormOrderBankCard />} />
                  <Route path="/bk/new_bank_card" element={<FormAddBankCard />} />
                  <Route path="/bk/card" element={<BankCard />} />
                  <Route path="/mm/new_mobile_money_account" element={<FormAddMobileMoneyAccount />} />
                  <Route path="/mm/account" element={<MobileMoneyAccount />} />

                  <Route path="/bc/new_blockchain_account" element={<FormNewBlockchainAccount />} />
                  <Route path="/bc/account" element={<BlockchainAccount />} />

                  <Route path="/pm/add_payment_method" element={<FormAddPaymentMethod />} />
                  <Route path="/pm/payment_method" element={<PaymentMethod />} />

                  <Route path="/al/:alertId" element={<AlertPage />} />
                  <Route path="/al/:alertId/edit" element={<FormEditAlert />} />
                  <Route path="/new_webinar" element={<FormNewWebinar />} />
                  <Route path="/wn/:webinarId" element={<Webinar />} />
                  <Route path="/wn/:webinarId/edit" element={<FormEditWebinar />} />

                  <Route path="/search" element={<Search />} />
                  <Route path="/p/:productId" element={<Product />} />
                  <Route path="/p/product/edit" element={<FormEditProduct />} />
                  <Route path="/s/:serviceId" element={<Service />} />
                  <Route path="/s/service/edit" element={<FormEditService />} />
                  <Route path="/t/:tenderId" element={<Tender />} />
                  <Route path="/t/tender/edit" element={<FormEditTender />} />
                  <Route path="/r/:rentalId" element={<Rental />} />
                  <Route path="/r/rental/edit" element={<FormEditRental />} />

                  <Route path="/cart" element={<Cart />} />
                  <Route path="/cart_checkout" element={<CartCheckout />} />
                  <Route path="/cart_summary" element={<CartSummary />} />

                  <Route path="/rl/:requestId" element={<RequestLogistics />} />
                  <Route path="/new_quotation" element={<FormNewQuotation />} />
                  <Route path="/it/packageItinerary/edit" element={<FormEditPackageItinerary />} />

                  <Route path="/rf/:requestId" element={<RequestFinancing />} />

                  <Route path="/ra/:requestId" element={<RequestAudit />} />

                  <Route path="/new_stock" element={<FormNewStock />} />
                  <Route path="/st/:stockId" element={<Stock />} />
                  <Route path="/st/stock/edit" element={<FormEditStock />} />
                  <Route path="/new_warehouse" element={<FormNewWarehouse />} />
                  <Route path="/wh/:warehouseId" element={<Warehouse />} />
                  <Route path="/wh/warehouse/edit" element={<FormEditWarehouse />} />

                  <Route path="/new_supplychain" element={<FormNewSupplychain />} />
                  <Route path="/sc/:supplychainId" element={<Supplychain />} />
                  <Route path="/sc/supplychain/edit" element={<FormEditSupplychain />} />
                  <Route path="/supplychains" element={<Supplychains />} />

                  <Route path="/conversations" element={<ListConversations />} />
                  <Route path="/c/:conversationId" element={<Conversation />} />
                  <Route path="/c/conversation/edit" element={<EditConversation />} />
                  <Route path="/list_contacts" element={<ListContacts />} />

                  <Route path="/company/manage_members" element={<ManageCompanyMembers />} />
                  <Route path="/new_position" element={<FormNewPosition />} />
                  <Route path="/j/:positionId" element={<Position />} />

                  <Route path="/purchases" element={<ListPurchases />} />
                  <Route path="/sells" element={<ListSells />} />
                  <Route path="/new_deal" element={<FormNewDeal />} />
                  <Route path="/new_multi_buyers_deal" element={<FormNewMultiDeals />} />
                  <Route path="/d/:dealId" element={<Deal />} />
                  <Route path="/d/deal/edit" element={<FormEditDeal />} />
                  <Route path="/o/:orderId" element={<Order />} />
                  <Route path="/o/order/edit" element={<FormEditOrder />} />

                  <Route path="/email_verification/:key" element={<EmailVerification />} />

                  <Route path="/join_complex_owner_network" element={loggedUser?.id ? <FormNewComplexOwner /> : <JoinComplexOwnerNetwork />} />
                  <Route path="/join_fivezer_network" element={<FormNewComplexOwner />} />
                  <Route path="/onboarding_complex_owner" element={<OnBoardingComplexOwner />} />
                  <Route path="/complex_owner/edit" element={<FormEditComplexOwner />} />
                  <Route path="/new_player" element={<FormNewPlayer />} />
                  <Route path="/player/edit" element={<FormEditPlayer />} />
                  <Route path="/new_complex" element={<FormNewComplex />} />
                  <Route path="/cx/:complexId" element={<Complex />} />
                  <Route path="/cx/complex/edit" element={<FormEditComplex />} />
                  <Route path="/new_field" element={<FormNewField />} />
                  <Route path="/fd/:fieldId" element={<Field />} />
                  <Route path="/fd/field/edit" element={<FormEditField />} />

                  <Route path="/new_livestream" element={<FormNewLivestream />} />
                  <Route path="/ls/:livestreamId" element={<Livestream />} />

                  <Route path="/africantum" element={<HomeAdmin />} />
                  <Route path="/africantum/users" element={<UsersAdmin />} />
                  <Route path="/africantum/company_partnerships" element={<PartnershipsAdmin />} />
                  <Route path="/africantum/kycs" element={<KYCsAdmin />} />
                  <Route path="/africantum/company_recommendations" element={<CompanyRecommendationsAdmin />} /> */}

                  {/* ANYTHING BELOW MUST STAY AT LAST POSITION */}
                  {/* <Route path="/new_company" element={<FormNewCompany />} />
                  <Route path="/company/edit" element={<FormEditCompany />} />
                  <Route path="/:companyName/members" element={<CompanyMembers />} />
                  <Route path="/:companyName" element={<Company />} />
                  <Route path="/badge/:companyName" element={<CompanyBadge />} />
                  <Route path="/:companyName/store" element={<Store />} />
                  <Route path="/:companyName/supplychains" element={<Supplychains />} />

                  <Route path="*" element={<NoMatch />} /> */}
                </Routes>
              </Grid>
              {(isSmallMobile || isMobile || isTablet) && !window.location.pathname?.includes('/c/') && !window.location.pathname?.includes('/badge/') && window.location.pathname !== '/onboarding' ? (
                <Slide appear={false} direction="up" in={!trigger}>
                  <Paper
                    sx={{
                      position: 'fixed',
                      zIndex: '1100',
                      bottom: '0',
                      left: '0',
                      right: '0',
                      borderRadius: '0',
                    }}
                    elevation={3}
                    className={'bottom-nav'}
                  >
                    <BottomNavigation value={value} onChange={handleChange} sx={{ 'bgcolor': 'transparent', '& .MuiSvgIcon-root': { fontSize: { xs: '1.1em', sm: '1.4em', md: '1.5em' } }, '& a.MuiBottomNavigationAction-root': { 'minWidth': '20px', '& .MuiBottomNavigationAction-label': { fontSize: { xs: '.6em', sm: '.8em', md: '.8em' } } } }}>
                      <BottomNavigationAction label={<FormattedMessage id="App_Home" />} value="home" component={Link} to="/" icon={<HomeIcon />} />
                      <BottomNavigationAction
                        label={<FormattedMessage id="App_Contacts" />}
                        value="conversations"
                        component={Link}
                        to={session?.status ? `/conversations` : '/login'}
                        sx={{
                          display: session?.status ? 'inline-flex' : 'none',
                        }}
                        icon={
                          <Badge max={100} badgeContent={_.sum(loadedConversations.data?.findConversationByParticipantsIDs?.map((c: any) => c?.messages?.filter((m: any) => (m?.status === 'UNREAD' || m?.status === 'PARTIALLY_SEEN') && !m?.viewers?.find((u: any) => ALL_PARTICIPANTS?.find((p: any) => p?.id === u?.id)))?.length))} color="error">
                            <ChatsIcon />
                          </Badge>
                        }
                      />
                      <BottomNavigationAction
                        label={<Typography sx={{ fontSize: { xs: '.8em', sm: '.9em', md: '1em' } }}>{_.shuffle(['#GoForIt', '#JustDoIt', '#ConsistencyIsKey'])[0]}</Typography>}
                        // showLabel
                        // value="home"
                        onClick={toggleDrawer('bottom', true)}
                        sx={{
                          display: session?.status ? 'inline-flex' : 'none',
                        }}
                        icon={<AddBoxOutlined sx={{ height: { xs: '25px', md: '30px' }, width: 'auto', color: 'rgba(31, 175, 146, 1)' }} />}
                      />
                      <BottomNavigationAction
                        label={<FormattedMessage id="App_Purchases" />}
                        value="purchases"
                        component={Link}
                        to={session?.status ? `/purchases` : '/login'}
                        sx={{
                          display: session?.status ? 'inline-flex' : 'none',
                        }}
                        icon={<PurchasesIcon />}
                      />
                      <BottomNavigationAction
                        label={<>{loggedUserActivePosition?.role || loggedUser?.username || <FormattedMessage id="App_User" />}</>}
                        // showLabel={loggedUser?.id !== undefined}
                        value="profile"
                        component={Link}
                        to={session?.status ? `/profile` : '/login'}
                        icon={
                          session?.status ? (
                            <Avatar
                              src={loggedUser?.avatar?.path}
                              sx={{
                                background: '#F7FBFA',
                                border: 'solid 1px #F7FBFA',
                                height: {
                                  xs: '30px',
                                  sm: '32px',
                                  md: '35px',
                                  xl: '40px',
                                },
                                width: {
                                  xs: '30px',
                                  sm: '32px',
                                  md: '35px',
                                  xl: '40px',
                                },
                                margin: 'auto',
                              }}
                            />
                          ) : (
                            <SingleMemberIcon />
                          )
                        }
                      />
                    </BottomNavigation>
                  </Paper>
                </Slide>
              ) : (
                ''
              )}

              {ANCHORS.map((anchor: Anchor) => (
                <React.Fragment key={anchor}>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                    disableSwipeToOpen
                    ModalProps={{
                      keepMounted: false,
                      sx: { backdropFilter: 'blur(8px)' },
                    }}
                    PaperProps={{
                      sx: {
                        borderTopRightRadius: '25px',
                        borderTopLeftRadius: '25px',
                      },
                    }}
                  >
                    <Grid container sx={{ pt: '0px' }}>
                      <SwitchActivePosition dark_mode={false} />
                    </Grid>
                    <List onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)} sx={{ p: '0 0 50px' }}>
                      <ListItemButton divider>
                        <Button
                          variant="text"
                          component={Link}
                          to={session?.status ? '/cart' : '/'}
                          fullWidth
                          sx={{
                            '&:hover': {
                              background: 'transparent !important',
                            },
                          }}
                          startIcon={
                            <Badge badgeContent={ALL_ITEMS} color="error">
                              <ShoppingCartIcon />
                            </Badge>
                          }
                        >
                          Cart
                        </Button>
                      </ListItemButton>

                      <ListItemButton divider>
                        <Button
                          variant="text"
                          fullWidth
                          onClick={() => handleOpenNewPost(anchor)}
                          sx={{
                            '&:hover': {
                              background: 'transparent !important',
                            },
                          }}
                          startIcon={<PostAdd />}
                        >
                          New Post
                        </Button>
                      </ListItemButton>
                      {window.location.pathname === '/dashboard' ? (
                        ''
                      ) : (
                        <ListItemButton>
                          <Button
                            variant="text"
                            component={Link}
                            to={session?.status ? '/dashboard' : '/'}
                            fullWidth
                            sx={{
                              '&:hover': {
                                background: 'transparent !important',
                              },
                            }}
                            startIcon={<DashboardIcon />}
                          >
                            Dashboard
                          </Button>
                        </ListItemButton>
                      )}
                    </List>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Grid>

            <LazyLoadingWrapper>
              <NewPostModal open={openPost} handleClose={() => setOpenPost(false)} />
            </LazyLoadingWrapper>
          </AppContext.Provider>
        </Grid>
      </IntlProvider>
    </BrowserRouter>
  );
};

export const AppContext = React.createContext<IAppContent>(UNDEFINED_AS_ANY);

export default React.memo(App);
